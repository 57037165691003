<template>
  <v-row>
    <v-col cols="12" class="px-0">
      <v-toolbar color="transparent" flat height="55">
        <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">
          <router-link :to="{name: 'Applications'}" class="subtitle-1 routerLink font-weight-medium">Dashboard</router-link>
          <v-icon class="mx-1" color="grey-500">mdi-chevron-right</v-icon>
          {{$route.name === 'Favorites' ? 'Favoritos' : 'Predefinidos'}}
          <v-chip class="ml-1" color="blue-500" text-color="white" small>Beta</v-chip>
        </v-toolbar-title>
      </v-toolbar>
    </v-col>
    <v-col cols="11">
      <template v-if="dashboard.length">
        <!-- dashboard fix -->
        <template v-if="Object.keys(dashboardFix).length !== 0">
          <v-card class="card-apps" @click.prevent="goTo" flat>
            <v-card-text class="py-6 px-0">
              <v-list class="transparent" three-line>
                <v-list-item>
                  <v-list-item-icon>
                    <img :src="require(`@/assets/appIcon-menu--Reports--active.svg`)" width="24px">
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1 grey-500--text font-weight-medium">{{dashboardFix.name}}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-row>
                        <v-col cols="11">{{dashboardFix.description}}</v-col>
                        <v-col cols="1" class="mt-0 text-left">
                          <div class="fix-icon">
                            <v-icon color="blue-500">mdi-pin</v-icon>
                          </div>
                          <v-menu offset-y transition="scale-transition" @click="changeRoute = true" width="360px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on"  outlined color="grey-500">•••</v-btn>
                            </template>
                            <v-list class="transparent py-2">
                              <v-list-item class="py-1" @click="dashboardFix = {}">
                                <v-list-item-title>
                                  <v-icon color="grey-500">mdi-pin </v-icon>Desfijar de la parte superior de la página
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item class="py-1" @click="addToFavoriteDashboard(dashboardFix)">
                                <v-list-item-title>
                                  <v-icon color="grey-500">{{isFavorite(dashboardFix.id) ? 'mdi-star' : 'mdi-star-outline'}} </v-icon>
                                  <span class="ml-1">{{isFavorite(dashboardFix.id) ? 'Eliminar de tus favoritos' : 'Añadir a favoritos'}}</span>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
          <v-divider class="my-5" />
        </template>
        <!-- end dashboard fix -->
        <!-- dashboard blank --
        <v-hover v-slot:default="{ hover }" open-delay="200">
          <v-card :class="`${dashboardFix.length ? 'my-5' : 'mb-5'} card-apps`" :to="{name: 'DashboardCreate'}" flat outlined rounded>
            <v-card-text class="py-6 px-0">
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-icon>
                    <img :src="require(`@/assets/appIcon-menu--Reports--active.svg`)" width="24px">
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1 grey-500--text font-weight-medium">Dashboard en blanco</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-row>
                        <v-col cols="11"> Crea tu propio Dashboard</v-col>

                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon class="pr-12 py-3" v-if="hover">
                    <v-icon class="mr-5" color="blue-500">mdi-plus</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-hover>
        <!- end dashboard blank -->
        <v-card :class="`${dashboardFix.length ? 'my-5' : 'mb-5'} card-apps`" v-for="d in dashboard" :key="d.id" @click="goTo" flat outlined rounded>
          <v-card-text class="py-6 px-0">
            <v-list class="transparent" three-line>
              <v-list-item>
                <v-list-item-icon>
                  <img :src="require(`@/assets/appIcon-menu--Reports--active.svg`)" width="24px">
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-1 grey-500--text font-weight-medium">{{d.name}}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row>
                      <v-col cols="11">{{d.description}}</v-col>
                        <v-col cols="1" class="my-0 text-left" >
                          <v-menu offset-y transition="scale-transition" @click="changeRoute = true" width="360px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on"  outlined color="grey-500">•••</v-btn>
                          </template>
                          <v-list class="transparent py-2">
                            <v-list-item class="py-1" @click="dashboardFix = d">
                              <v-list-item-title>
                                <v-icon color="grey-500">mdi-pin-outline </v-icon>Fijar en la parte superior de la página
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="py-1" @click="addToFavoriteDashboard(d);">
                              <v-list-item-title>
                                <v-icon color="grey-500">{{isFavorite(d.id) ? 'mdi-star' : 'mdi-star-outline'}} </v-icon>
                                <span class="ml-1">{{isFavorite(d.id) ? 'Eliminar de tus favoritos' : 'Añadir a favoritos'}}</span>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </template>
      <template v-else-if="$route.name === 'Favorites'">
        <v-col class="pb-6 pt-4 text-center">
          <v-icon class="d-block" color="grey-500" size="70">mdi-star</v-icon>
          <span class="d-block subtitle-1 my-2">Aún no tienes favoritos agregados</span>
          <span class="d-block body-2">Agrega dashboard a tus favoritos para priorizar tu listado</span>
        </v-col>
      </template>
    </v-col>
  </v-row>
</template>
<script>
import defaulfDashboardList from '@/modules/reports/components/DefaulfDashboardList'
import favorites from '@/modules/reports/components/Favorites'
export default {
  data: () => ({
    defaulfDashboardList: defaulfDashboardList,
    favorites: favorites,
    dashboard: [],
    dashboardFix: {},
    changeRoute: false
  }),
  created () {
    if (this.$route.name === 'Favorites') {
      this.dashboard = this.favorites
     } else {
      this.dashboard = this.defaulfDashboardList
     }
  },
  methods: {
    isFavorite (id) {
      return this.favorites.findIndex(favorite => favorite.id === id) !== -1
    },

    goTo () {
      if (!this.changeRoute) {
        this.$router.push({ name: 'ReportList'})
      }
    },
    addToFavoriteDashboard (item) {
      if (this.isFavorite(item.id)) {
        let index = this.favorites.findIndex(favorite => favorite.id === item.id)
        this.favorites.splice(index, 1)
      } else {
        this.favorites.push(item)
        this.$dialog.message.info('El Dashboard se ha añadido a favoritos')
      }
    }
  }
}
</script>
<style scoped>
.v-list--three-line .v-list-item .v-list-item__subtitle, .v-list-item--three-line .v-list-item__subtitle {
  -webkit-line-clamp: 3 !important;
}
.fix-icon {
  position: absolute;
  top: -10px;
  right: 15px;
}
</style>